.print {
  display: none;
}
@media print {
  .noPrint {
    display: none;
  }
  .print {
    display: flex;
  }
  .box {
    padding: 0 !important;
    border: none !important;
  }
}
