@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url(./fonts/Light.otf) format("truetype");
}

@font-face {
  font-family: "Gilroy-bold";
  src: local("Gilroy"), url(./fonts/ExtraBold.otf) format("truetype");
}

body {
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: "Gilroy", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
